// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";

const {
  getCurrentLoggedInSessionTokenWithPromise,
} = require("../config/userPool");

const { CCTV_ENDPOINT } = config.api_endpoint;
const { VIEW_CCTV, VIEW_CCTV_LIMITED } = config.permissions;
const initialState = {
  loading: true,
  cctvs: [],
  packages: [],
  error: null,
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("current_user"));
};

const shouldAccessAll = (user) => {
  if (user.permissions.indexOf(VIEW_CCTV) > -1) {
    return true;
  } else if (user.permissions.indexOf(VIEW_CCTV_LIMITED) > -1) {
    return false;
  }
  return false;
};

export const fetchAllCCTVs = createAsyncThunk(
  "cctv/fetchAllCCTVs",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const currentUser = getCurrentUser();

      const { results } = await api.get(
        CCTV_ENDPOINT,
        {
          package: !shouldAccessAll(currentUser)
            ? currentUser["package"].join(",")
            : "",
        },
        token
      );
      const packages = [];
      const cctvs = [];

      for (let item of results) {
        if (cctvs.indexOf(item.camera_no) < 0) {
          cctvs.push(item);
        }
        if (packages.indexOf(item.package) < 0) {
          packages.push(item.package);
        }
      }
      console.log("cctvs: ", results, ", packages: ", packages);
      return {
        cctvs: results,
        packages,
      };
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

const cctvSlice = createSlice({
  name: "cctv",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCCTVs.pending, (state) => {
        console.log("fetch all cctvs pending: ", state);
        state.loading = true;
      })
      .addCase(fetchAllCCTVs.fulfilled, (state, action) => {
        console.log("fetch all cctvs fulfilled: ", state, action);
        state.loading = false;
        state.cctvs = action.payload.cctvs;
        state.packages = action.payload.packages;
      })
      .addCase(fetchAllCCTVs.rejected, (state, action) => {
        console.log("fetch all cctvs rejected: ", state, action);
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const cctvReducer = cctvSlice.reducer;
export default cctvReducer;
