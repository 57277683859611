import React from "react";
import Image from "../../../assets/images/Map/Example.png";
import { Button } from "@progress/kendo-react-buttons";
import { CustomDatePicker } from "../../../components/form/custom-sitrep-datepicker";

export const ECM_Historical_Record = () => {
  return (
    <div style={{ height: "calc(100vh - 80px)" }}>
      <div className="ECM_historical_title">
        <Button className="back-btn">
          <span className="k-icon k-i-arrow-chevron-left"></span>
        </Button>
        <div>ARC 001</div>
      </div>
      <div className="ECM_historical_date_box">
        <div>Choose date</div>
        <div style={{ width: "150px" }}>
          <CustomDatePicker
            value={new Date()}
            format={"DD MMM YYYY"}
            // MinDate={props.startDate}
            MaxDate={new Date()}
            // onChange={(e) => sitrepFilter(e)}
          />
        </div>
      </div>
      <div>
        <img src={Image} />
      </div>
      <div className="ECM_historical_detail_box">
        <div className="ECM_historical_detail_sec_box">
          <div className="ECM_historical_sec_box_text">Date:</div>
          <div>2023-01-02, 11:20 am</div>
        </div>
        <div className="divider" style={{ width: "100%", margin: "0px" }} />

        <div className="ECM_historical_detail_sec_box">
          <div className="ECM_historical_sec_box_text">Level:</div>

          <div>27 mg/L</div>
        </div>
        <div className="divider" style={{ width: "100%", margin: "0px" }} />

        <div className="ECM_historical_detail_sec_box">
          <div className="ECM_historical_sec_box_text">Acknowledge by:</div>
          <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            dutymanager@weeswares.com
          </div>
        </div>
        <div className="divider" style={{ width: "100%", margin: "0px" }} />
      </div>
      <div className="ECM_historical_detail_box">exceed 50 mg/s</div>
      <div className="divider" style={{ margin: "16px 20px" }} />
    </div>
  );
};
