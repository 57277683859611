//redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";

import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_INCIDENT_OVERVIEW_ENDPOINT } = config.api_endpoint;
const { VIEW_INCIDENT, VIEW_INCIDENT_LIMITED } = config.permissions;
const initialState = {
  loading: true,
  Incident: [],
  error: null,
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("current_user"));
};

const shouldAccessAll = (user) => {
  if (user.permissions.indexOf(VIEW_INCIDENT) > -1) {
    return true;
  } else if (user.permissions.indexOf(VIEW_INCIDENT_LIMITED) > -1) {
    return false;
  }
  return false;
};

export const fetchALLDashboardData = createAsyncThunk(
  "dashboard/fetchALLDashboardData",
  async (date, { rejectWithValue }) => {
    try {
      const currentUser = getCurrentUser();
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        GET_INCIDENT_OVERVIEW_ENDPOINT,
        {
          startdate: date[0],
          enddate: date[1],
          package: !shouldAccessAll(currentUser)
            ? currentUser["package"].join(",")
            : "",
        },
        token
      );
      console.log(results);
      let res = {};
      for (let i = 0; i < results.data.length; i++) {
        let k = Object.keys(results.data[i])[0];
        res[k] = results.data[i][k];
      }
      return {
        Incident: res,
      };
    } catch (e) {
      return rejectWithValue(e.details);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchALLDashboardData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchALLDashboardData.fulfilled, (state, action) => {
        console.log(action.payload);
        state.loading = false;
        state.Incident = action.payload.Incident;
      })
      .addCase(fetchALLDashboardData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const dashboardReducer = dashboardSlice.reducer;
export default dashboardReducer;
