import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_IMAGE_ENDPOINT } = config.api_endpoint;
const initialState = {
  loading: true,
  Image: [],
  error: null,
};

export const fetchImage = createAsyncThunk(
  "image/fetchImage",
  async (url, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        GET_IMAGE_ENDPOINT,
        {
          url: url,
        },
        token
      );
      console.log(results);
      return {
        Image: results.data,
      };
    } catch (e) {
      return rejectWithValue(e.details);
    }
  }
);

const ImageSlice = createSlice({
  name: "image",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchImage.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.Image = action.payload.Image;
      })
      .addCase(fetchImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const ImageReducer = ImageSlice.reducer;
export default ImageReducer;
