// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_ECM_DRAIN_ENDPOINT } = config.api_endpoint;
const { VIEW_DRAINS, VIEW_DRAINS_LIMITED, VIEW_ECM, VIEW_ECM_LIMITED } =
  config.permissions;

const initialState = {
  loading: true,
  ECMDrain: [],
  error: null,
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("current_user"));
};

const shouldAccessAll = (user) => {
  if (user.permissions.indexOf(VIEW_DRAINS) > -1) {
    return true;
  } else if (user.permissions.indexOf(VIEW_DRAINS_LIMITED) > -1) {
    return false;
  }
  return false;
};

export const fetchALLECMDRAIN = createAsyncThunk(
  "ecmdrain/fetchALLECMDRAIN",
  async (_, { rejectWithValue }) => {
    try {
      const currentUser = getCurrentUser();
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        GET_ECM_DRAIN_ENDPOINT,
        {
          package: !shouldAccessAll(currentUser)
            ? currentUser["package"].join(",")
            : "",
        },
        token
      );
      return {
        ECMDrain: results.results,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const ECMDrainSlice = createSlice({
  name: "ecmdrain",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchALLECMDRAIN.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchALLECMDRAIN.fulfilled, (state, action) => {
        state.loading = false;
        state.ECMDrain = action.payload.ECMDrain;
      })
      .addCase(fetchALLECMDRAIN.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const ECMDrainReducer = ECMDrainSlice.reducer;
export default ECMDrainReducer;
