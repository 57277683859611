import React, { useEffect, useState } from "react";
// api
import api from "./config/api";
import config from "./config/config";

import { deleteFirebaseToken } from "./firebase";

const {
  signout,
  getCurrentLoggedInSessionTokenWithPromise,
} = require("./config/userPool");

const BrowserTabCloseEventLogout = () => {
  const { SAVE_USER_DEVICE_TOKEN_ENDPOINT } = config.api_endpoint;

  const logout = async () => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();

      // deleteFirebaseToken();
      // console.log("Deleted Token");

      caches.keys().then((names) => {
        names.forEach((name) => {
          console.log(name);
          caches.delete(name);
        });
      });

      signout();
      localStorage.removeItem("login");
      localStorage.removeItem("username");
      localStorage.removeItem("session_id");
      localStorage.removeItem("deviceToken_id");

      // if (localStorage.getItem("deviceToken_id")) {
      //   api
      //     .remove(
      //       SAVE_USER_DEVICE_TOKEN_ENDPOINT,
      //       JSON.stringify({
      //         device_id: localStorage.getItem("deviceToken_id"),
      //       }),
      //       token
      //     )
      //     .then((res) => {
      //       console.log("delete device token result: ", res);
      //       localStorage.removeItem("deviceToken_id");
      //     })
      //     .catch((err) => {
      //       console.log("delete device token err", err);
      //       localStorage.removeItem("deviceToken_id");
      //     });
      // }

      window.location.href = "/";
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {
        // alert("This page is reloaded");
        // logout();
      } else {
        // alert("This page is not reloaded");
        logout();
      }
    }
  }, []);

  return <div></div>;
};
export default BrowserTabCloseEventLogout;
