import React from "react";
// css
import "./noPermission.css";

const NoPermission = () => {
  return (
    <div className="no-permission-div">
      You do not have permission to access the page.
    </div>
  );
};

export default NoPermission;
