import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import "mapbox-gl/dist/mapbox-gl.css";
import { Helmet } from "react-helmet";

import App from "./App";
import { store, history } from "./store";
import CognitoProvider from "./cognitoProvider";
// css
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <CognitoProvider>
      <Router history={history}>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>
        <App />
      </Router>
    </CognitoProvider>
  </Provider>
);
