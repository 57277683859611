// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { ANNOTATION_ENDPOINT } = config.api_endpoint;
const initialState = {
  loading: true,
  annotation: [],
  marker: [],
  error: null,
};

export const fetchAllANNOTATIONS = createAsyncThunk(
  "annotation/fetchAllANNOTATIONS",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(ANNOTATION_ENDPOINT, {}, token);
      console.log(results);
      return {
        annotation: results.markup[0].PolygonData,
        marker: results.markup[0].Marker,
      };
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

const annotationSlice = createSlice({
  name: "annotation",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllANNOTATIONS.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllANNOTATIONS.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.annotation = action.payload.annotation;
        state.marker = action.payload.marker;
      })
      .addCase(fetchAllANNOTATIONS.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const annotationReducer = annotationSlice.reducer;
export default annotationReducer;
