// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_AUDIT_TRAIL_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: true,
  audit: [],
  error: null,
};

export const fetchALLAUDIT = createAsyncThunk(
  "audit/fetchALLAUDIT",
  async (id, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        GET_AUDIT_TRAIL_ENDPOINT,
        { event_id: id },
        token
      );
      console.log(results);
      return {
        audit: results,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const auditSlice = createSlice({
  name: "audit",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchALLAUDIT.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchALLAUDIT.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.audit = action.payload.audit.data;
      })
      .addCase(fetchALLAUDIT.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const auditReducer = auditSlice.reducer;
export default auditReducer;
