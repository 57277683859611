import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

// api
import api from "../config/api";
import config from "../config/config";

import { deleteFirebaseToken } from "../firebase";

const {
  signout,
  getCurrentLoggedInSessionTokenWithPromise,
} = require("../config/userPool");
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = () => {
  const idleTimeout = 1000 * 900;
  const [isIdle, setIdle] = useState(false);
  const { SAVE_USER_DEVICE_TOKEN_ENDPOINT } = config.api_endpoint;

  const onIdle = async () => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();

      // deleteFirebaseToken();
      // console.log("Deleted Token");

      caches.keys().then((names) => {
        names.forEach((name) => {
          console.log(name);
          caches.delete(name);
        });
      });

      signout();
      localStorage.removeItem("login");
      localStorage.removeItem("username");
      localStorage.removeItem("session_id");
      localStorage.removeItem("deviceToken_id");

      // if (localStorage.getItem("deviceToken_id")) {
      //   api
      //     .remove(
      //       SAVE_USER_DEVICE_TOKEN_ENDPOINT,
      //       JSON.stringify({
      //         device_id: localStorage.getItem("deviceToken_id"),
      //       }),
      //       token
      //     )
      //     .then((res) => {
      //       console.log("delete device token result: ", res);
      //       localStorage.removeItem("deviceToken_id");
      //     })
      //     .catch((err) => {
      //       console.log("delete device token err", err);
      //       localStorage.removeItem("deviceToken_id");
      //     });
      // }

      window.location.href = "/";
    } catch (e) {
      console.log(e);
    }
  };

  //    const onIdle = () => {
  //        try{
  //            console.log("Idle");
  //            console.log("Logout");
  //
  //            deleteFirebaseToken();
  //            console.log("Deleted Token");
  //
  //            signout();
  //            localStorage.removeItem("login");
  //            localStorage.removeItem("username");
  //            window.location.href = "/";
  //
  //        }
  //        catch(e)
  //        {
  //            console.log(e);
  //        }
  //
  //    }

  const handleIdle = () => {
    try {
      setIdle(true);
      console.log("Logout");
    }
    catch (e) {
      console.log(e);
    }

  }
  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: idleTimeout,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500
  })
  return {
    isIdle,
    setIdle,
    idleTimer
  }
}
export default useIdleTimeout;