// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";

const {
  getCurrentLoggedInSessionTokenWithPromise,
} = require("../config/userPool");

const { GET_WEATHER_ENDPOINT } = config.api_endpoint;
const initialState = {
  loading: true,
  weatherData: [],
  error: null,
};

export const fetchWeatherData = createAsyncThunk(
  "weather/fetchWeatherData",
  async (UID, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const { data: results } = await api.get(GET_WEATHER_ENDPOINT, {}, token);
      console.log("w-results:", results);
      return { weatherData: results };
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

const weatherSlice = createSlice({
  name: "weather",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeatherData.pending, (state) => {
        console.log("fetch weather data pending: ", state);
        state.loading = true;
      })
      .addCase(fetchWeatherData.fulfilled, (state, action) => {
        console.log("fetch weather data fulfilled: ", state, action);
        state.loading = false;
        state.weatherData = action.payload.weatherData;
      })
      .addCase(fetchWeatherData.rejected, (state, action) => {
        console.log("fetch weather data rejected: ", state, action);
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const weatherReducer = weatherSlice.reducer;
export default weatherReducer;
