import React, { useEffect, useRef, useState } from "react";
import "./more.css";
import { useMediaQuery } from "react-responsive";
import MobileHeaderFiltered from "../../components/mobileHeaderFiltered/mobileHeaderFiltered";
import { MORE_ROUTE } from "../../routes";
import { Input, TextArea, TextBox } from "@progress/kendo-react-inputs";
import {
  ComboBox,
  DropDownList,
  MultiSelect,
} from "@progress/kendo-react-dropdowns";
import UploadIcon from "../../assets/images/Work Declaration/upload.svg";
import DeleteIcon from "../../assets/images/Work Declaration/delete.svg";
import { Loader } from "@progress/kendo-react-indicators";
import {
  getCurrentLoggedInSessionTokenWithPromise,
  getCurrentLoggedInUserWithPromise,
} from "../../config/userPool";
import api from "../../config/api";
import config from "../../config/config";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

export const ContactUs = () => {
  const { general_config_data } = useSelector((state) => state.generalConfig);
  const Package = general_config_data.general_config_data.package;
  const usernameValidationMessage = "Please enter valid email address!";
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const FeedbackAttachment = useRef(null);
  const [companies, setCompanies] = useState([]);
  const [roles, setRoles] = useState([]);
  const [showDepartment, setShowDepartment] = useState(false);
  const [cagroles, setCAGRoles] = useState([]);
  const [tempFilter, setTempFilter] = useState({
    company_name: "",
    package: [],
    user_group_name: [],
  });
  const [isTempFilter, setIsTempFilter] = useState({
    company_name: false,
    package: false,
    user_group_name: false,
  });
  const [filterLoading, setFilterLoading] = useState({
    company_name: false,
    package: false,
    user_group_name: false,
  });
  const [detailData, setDetailData] = useState({
    name: "",
    email: "",
    company: "",
    package: [],
    role: [],
    category: "",
    subject: "",
    message: "",
    attachment: [],
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useNavigate();
  const { USER_REG_COMPANY_LIST, USER_REG_USER_GROUP_LIST } =
    config.api_endpoint;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    email: false,
    attachment: false,
    maximun_attachment: false,
  });
  const { UPLOAD_USER_IMAGE, SUBMIT_FEEDBACK_ENDPOINT } = config.api_endpoint;

  const UploadAttachment = (e) => {
    if (
      (Math.round(e.target.files[0].size) / (1024 * 1024)).toFixed(2) < 10.1
    ) {
      detailData.attachment.push(e.target.files[0]);
      setDetailData({ ...detailData });
      setError({
        ...error,
        attachment: false,
      });
    } else {
      setError({
        ...error,
        attachment: true,
      });
    }
  };

  const checkValidation = (e, type) => {
    // if (type === "email") {
    //   if (!emailPattern.test(e)) {
    //     setError({
    //       ...error,
    //       email: true,
    //     });
    //   } else {
    //     setError({
    //       ...error,
    //       email: false,
    //     });
    //   }
    // }
    if (type === "submit") {
      if (
        // detailData.name === "" ||
        // detailData.email === "" ||
        // detailData.company === "" ||
        // detailData.package.length === 0 ||
        // detailData.role.length === 0 ||
        detailData.category === "" ||
        detailData.subject === "" ||
        detailData.message === "" ||
        // detailData.attachment.length === 0 ||
        // error.email ||
        error.attachment
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const UploadToAPI = async () => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const attachment = [];
    for (let i = 0; i < detailData.attachment.length; i++) {
      await api
        .post(
          UPLOAD_USER_IMAGE,
          JSON.stringify({
            filename: detailData.attachment[i].name,
            is_feedback_attach: true,
          }),
          token
        )
        .then(async (res) => {
          const result = res.data;
          if (result) {
            if (res.status_code === 200) {
              const formData = new FormData();
              for (let k in res.data.payload) {
                formData.append(k, res.data.payload[k]);
              }
              formData.append("file", detailData.attachment[i]);
              attachment.push({
                file_url: res.data.url,
                filename: detailData.attachment[i].name,
              });
              api
                .postDiff(res.data.bucket_url, formData)
                .then((res1) => {
                  if (res1 === undefined) {
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        });
    }
    return attachment;
  };

  const SubmitToAPI = async () => {
    setLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const current_user = await getCurrentLoggedInUserWithPromise();
    const result = await api.get(
      "/api-users/getUser",
      {
        uid: current_user["cognito:username"],
      },
      token
    );
    const attachment = await UploadToAPI();
    console.log(attachment);
    const role = [];
    detailData.role.map((i) => {
      role.push(i.user_group_name);
    });
    const submitData = {
      // name: detailData.name,
      // email: detailData.email,
      // company: detailData.company.company_name,
      // package: detailData.package.join(", "),
      // role: role.join(", "),
      user: result.data.sub,
      category: detailData.category,
      subject: detailData.subject,
      message: detailData.message,
      attachments: attachment,
    };
    console.log(submitData);
    api
      .post(SUBMIT_FEEDBACK_ENDPOINT, JSON.stringify(submitData), token)
      .then((res) => {
        if (res.status_code === 200) {
          setShowDepartment(false);
          // setTempFilter({
          //   ...tempFilter,
          //   company_name: "",
          //   package: [],
          //   user_group_name: [],
          // });
          // setFilterLoading({
          //   ...filterLoading,
          //   company_name: false,
          //   package: false,
          //   user_group_name: false,
          // });
          setDetailData({
            name: "",
            email: "",
            company: "",
            package: [],
            role: [],
            category: "",
            subject: "",
            message: "",
            attachment: [],
          });
          setError({
            ...error,
            email: false,
            attachment: false,
            maximun_attachment: false,
          });
          setLoading(false);
          setShowSuccess(true);
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    api
      .get(USER_REG_COMPANY_LIST, {}, null)
      .then((res) => {
        console.log("get company result: ", res);
        setCompanies(res.data);

        api
          .get(USER_REG_USER_GROUP_LIST, {}, null)
          .then((res) => {
            console.log("get user group result: ", res);
            var cag_roles = [];
            var non_cag_roles = [];
            for (var i = 0; i < res.data.length; i++) {
              if (
                res.data[i].user_group_name.startsWith("CAG") ||
                res.data[i].user_group_name.startsWith(
                  "Changi Airport Group"
                ) ||
                res.data[i].user_group_name.startsWith(
                  "Changi Airport International"
                )
              ) {
                cag_roles.push(res.data[i]);
              } else {
                non_cag_roles.push(res.data[i]);
              }
            }
            console.log("CAG Roles: ", cag_roles);
            console.log("Non CAG Roles: ", non_cag_roles);
            setCAGRoles(cag_roles);
            setRoles(non_cag_roles);
            setLoading(false);
          })
          .catch((err) => {
            console.log("get user group err", err);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log("get company err", err);
        setLoading(false);
      });
  }, []);

  const filtering = (data, target, filterValue) => {
    return data.filter((i) =>
      target === "package"
        ? i.replace(/ /g, "").toLowerCase().includes(filterValue.toLowerCase())
        : i[target]
            .replace(/ /g, "")
            .toLowerCase()
            .includes(filterValue.toLowerCase())
    );
  };

  const filterData = (filter, data) => {
    const targetedField = filter.field === undefined ? "package" : filter.field;
    return filtering(data, targetedField, filter.value);
  };
  const timeout = React.useRef();
  const handleFilterData = (event, data) => {
    const targetedField =
      event.filter.field === undefined ? "package" : event.filter.field;
    console.log(event.filter);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      console.log(filterData(event.filter, data));
      setTempFilter({
        ...tempFilter,
        [targetedField]: filterData(event.filter, data),
      });
      // setLoading(false);
      setFilterLoading({
        ...filterLoading,
        [targetedField]: false,
      });
      setIsTempFilter({
        ...isTempFilter,
        [targetedField]: true,
      });
    }, 500);
    // setLoading(true);
    setFilterLoading({
      ...filterLoading,
      [targetedField]: true,
    });
  };

  console.log(tempFilter, isTempFilter);

  return (
    <div className="ContactUs_main_box">
      {isMobile ? (
        <MobileHeaderFiltered
          title={"Add feedback"}
          route={MORE_ROUTE}
          icons={[]}
        />
      ) : (
        <MobileHeaderFiltered
          title={"Add feedback"}
          route={MORE_ROUTE}
          icons={[]}
        />
      )}

      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            height: "100vh",
          }}
        />
      ) : (
        <div className="ContactUs_partent_box morScrollbarDIV">
          <div className="ContactUs_fiedls_main_box">
            {/* <div className="ContactUs_Text_box" style={{ marginTop: "17px" }}>
              <div className="ContactUs_title">Name *</div>
              <TextBox
                placeholder=""
                className="ContactUs_Text_field"
                type="text"
                value={detailData.name}
                onChange={(e) =>
                  setDetailData({
                    ...detailData,
                    name: e.target.value,
                  })
                }
              />
            </div> */}
            {/* <div className="ContactUs_Text_box" style={{ marginTop: "17px" }}>
              <div className="ContactUs_title">Email *</div>
              <Input
                placeholder=""
                className="ContactUs_Text_field"
                pattern={emailPattern}
                minLength={2}
                autoComplete="off"
                type="email"
                validationMessage={usernameValidationMessage}
                value={detailData.email}
                onChange={(e) => {
                  checkValidation(e.target.value, "email");
                  setDetailData({
                    ...detailData,
                    email: e.target.value,
                  });
                }}
              />
              {error.email && (
                <div className="ContactUs_error_message">
                  {usernameValidationMessage}
                </div>
              )}
            </div> */}
            {/* <div className="ContactUs_Text_box" style={{ marginTop: "17px" }}>
              <div className="ContactUs_title">Company *</div> */}
            {/* <TextBox
              placeholder=""
              className="ContactUs_Text_field"
              type="text"
              value={detailData.company}
              onChange={(e) =>
                setDetailData({
                  ...detailData,
                  company: e.target.value,
                })
              }
            /> */}
            {/* <ComboBox
                // onChange={(e) => filter(e.target.value, "company")}
                onChange={(e) => {
                  setDetailData({
                    ...detailData,
                    company: e.target.value,
                    role: [],
                  });
                  if (e.target.value !== null) {
                    console.log(e.target.value);
                    if (
                      e.target.value.company_name == "CAG" ||
                      e.target.value.company_name == "Changi Airport Group" ||
                      e.target.value.company_name ==
                        "Changi Airport International"
                    ) {
                      setShowDepartment(true);
                      setIsTempFilter({
                        ...isTempFilter,
                        user_group_name: false,
                      });
                      setTempFilter({
                        ...tempFilter,
                        user_group_name: [],
                      });
                    } else {
                      setShowDepartment(false);
                      setIsTempFilter({
                        ...isTempFilter,
                        user_group_name: false,
                      });
                      setTempFilter({
                        ...tempFilter,
                        user_group_name: [],
                      });
                    }
                  } else {
                    setShowDepartment(false);
                    setIsTempFilter({
                      ...isTempFilter,
                      user_group_name: false,
                    });
                    setTempFilter({
                      ...tempFilter,
                      user_group_name: [],
                    });
                  }
                }}
                style={{
                  width: "100%",
                  minHeight: "48px",
                  marginTop: "10px",
                }}
                data={
                  isTempFilter.company_name
                    ? tempFilter.company_name
                    : companies
                }
                value={detailData.company}
                filterable={true}
                loading={filterLoading.company_name}
                onFilterChange={(e) => handleFilterData(e, companies)}
                textField="company_name"
                dataItemKey="id"
                defaultValue={-1}
              />
            </div> */}
            {/* <div className="ContactUs_Text_box" style={{ marginTop: "17px" }}>
              <div className="ContactUs_title">Package *</div> */}
            {/* <TextBox
              placeholder=""
              className="ContactUs_Text_field"
              type="text"
              value={detailData.package}
              onChange={(e) =>
                setDetailData({
                  ...detailData,
                  package: e.target.value,
                })
              }
            /> */}
            {/* <MultiSelect
                // onChange={(e) => filter(e.target.value, "package")}
                onChange={(e) =>
                  setDetailData({
                    ...detailData,
                    package: e.target.value,
                  })
                }
                style={{
                  width: "100%",
                  marginTop: "10px",
                  minHeight: "48px",
                }}
                data={
                  isTempFilter.package
                    ? tempFilter.package
                    : ["all", ...Package]
                }
                // data={["all", ...Package]}
                value={detailData.package}
                filterable={true}
                loading={filterLoading.package}
                onFilterChange={(e) => handleFilterData(e, ["all", ...Package])}
              />
            </div> */}
            {/* <div className="ContactUs_Text_box" style={{ marginTop: "17px" }}>
              <div className="ContactUs_title">Role</div> */}
            {/* <TextBox
              placeholder=""
              className="ContactUs_Text_field"
              type="text"
              value={detailData.role}
              onChange={(e) =>
                setDetailData({
                  ...detailData,
                  role: e.target.value,
                })
              }
            /> */}
            {/* <MultiSelect
                // className="Register_dropdown"
                className="ContactUs_Text_field"
                style={{
                  minHeight: "48px",
                  height: "auto",
                  // width: "300px",
                }}
                textField="user_group_name"
                dataItemKey="id"
                data={
                  isTempFilter.user_group_name
                    ? tempFilter.user_group_name
                    : showDepartment
                    ? cagroles
                    : roles
                }
                // data={showDepartment ? cagroles : roles}
                defaultValue=""
                value={detailData.role}
                filterable={true}
                loading={filterLoading.package}
                onFilterChange={(e) =>
                  handleFilterData(e, showDepartment ? cagroles : roles)
                }
                onChange={(e) =>
                  setDetailData({
                    ...detailData,
                    role: e.target.value,
                  })
                }
              /> */}
            {/* </div> */}
            <div className="ContactUs_Text_box" style={{ marginTop: "17px" }}>
              <div className="ContactUs_title">Category *</div>
              <DropDownList
                placeholder="Select"
                data={[
                  "Account Services",
                  "Functional Enquiries",
                  "General Enquiries",
                ]}
                className="ContactUs_dropdown"
                value={detailData.category}
                onChange={(e) =>
                  setDetailData({
                    ...detailData,
                    category: e.target.value,
                  })
                }
              />
            </div>
            <div className="ContactUs_Text_box" style={{ marginTop: "17px" }}>
              <div className="ContactUs_title">Subject *</div>
              <div>
                <Input
                  className="ContactUs_textarea "
                  style={{ height: "150px", minHeight: "48px" }}
                  rows={6}
                  autoSize={true}
                  value={detailData.subject}
                  onChange={(e) =>
                    setDetailData({
                      ...detailData,
                      subject: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="ContactUs_Text_box" style={{ marginTop: "17px" }}>
              <div className="ContactUs_title">Message *</div>
              <div>
                <TextArea
                  className="ContactUs_textarea ContactUs_Text_field"
                  style={{ height: "150px" }}
                  rows={6}
                  autoSize={true}
                  value={detailData.message}
                  onChange={(e) =>
                    setDetailData({
                      ...detailData,
                      message: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="ContactUs_Text_box" style={{ marginTop: "17px" }}>
              <div className="ContactUs_title">
                Attachment (Maximum 2 attachment of 10MB each)
              </div>
              {error.maximun_attachment && (
                <div style={{ position: "relative", height: "0" }}>
                  <div className="ContactUs_popup_main_box">
                    <div className="ContactUs_popup_text_box">
                      Cannot Upload More than 2 attachments
                    </div>
                    <div className="ContactUs_popup_tips" />
                  </div>
                </div>
              )}
              <div className="ContactUs_attachment_main_box">
                <div
                  className="ContactUs_attachment_upload_title"
                  onClick={() => {
                    if (detailData.attachment.length < 2) {
                      FeedbackAttachment.current.click();
                    }
                  }}
                  onMouseEnter={() => {
                    if (detailData.attachment.length === 2) {
                      setError({
                        ...error,
                        maximun_attachment: true,
                      });
                    }
                  }}
                  onMouseLeave={() => {
                    if (detailData.attachment.length === 2) {
                      setError({
                        ...error,
                        maximun_attachment: false,
                      });
                    }
                  }}
                >
                  <div>
                    <div>Add Attachment</div>
                  </div>
                  <div
                    className="ContactUs_upload_button"
                    style={{
                      pointerEvents:
                        detailData.attachment.length >= 2 ? "none" : "",
                      opacity: detailData.attachment.length >= 2 ? 0.5 : 1,
                    }}
                  >
                    <input
                      accept={[
                        "image/jpeg",
                        "image/png",
                        "image/jpg",
                        "application/pdf",
                        "application/msword",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        "application/vnd.ms-excel",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                      ]}
                      ref={FeedbackAttachment}
                      type="file"
                      hidden
                      onClick={(e) => (e.target.value = "")}
                      onChange={(e) => {
                        if (detailData.attachment.length < 2) {
                          UploadAttachment(e);
                        }
                      }}
                    />
                    Upload <img src={UploadIcon} />
                  </div>
                </div>
                {detailData.attachment.length > 0 &&
                  detailData.attachment.map((i, id) => {
                    return (
                      <div
                        id={id}
                        className="ContactUs_attachment_detail_main_box"
                      >
                        <div>
                          <div className="ContactUs_attachment_detail_text1">
                            {i.name}
                          </div>
                          <div className="ContactUs_attachment_detail_text2">
                            {(Math.round(i.size) / (1024 * 1024)).toFixed(2)} MB
                          </div>
                        </div>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            detailData.attachment.splice(id, 1);
                            setDetailData({ ...detailData });
                          }}
                        >
                          <img src={DeleteIcon} />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="ContactUs">
            For more information, please email to our CEreal administrator at
            <a
              style={{ padding: "5px" }}
              href="mailto:cereal@changiairport.com"
            >
              cereal@changiairport.com.
            </a>
          </div>
        </div>
      )}
      {!loading && (
        <div className="ContactUs_footer_main_box">
          <div
            className="ContactUs_footer_cancel_button"
            onClick={() => navigate(MORE_ROUTE)}
          >
            Cancel
          </div>
          <div
            className="ContactUs_footer_submit_button"
            onClick={SubmitToAPI}
            style={{
              pointerEvents:
                checkValidation("", "submit") === true ? "none" : "",
              opacity: checkValidation("", "submit") === true ? 0.5 : 1,
            }}
          >
            Submit
          </div>
        </div>
      )}
      {error.attachment && (
        <Dialog>
          <div
            className="ContactUs_popup_cross_button"
            onClick={() => setError({ ...error, attachment: false })}
          >
            X
          </div>
          <div className="ContactUs_popup_parent_box">
            <div className="ContactUs_popup_title">Attachement Size!</div>
            <div className="ContactUs_popup_detail">
              Attachment cannot upload more than 10 MB!
            </div>
            <div
              className="ContactUs_popup_close_button"
              onClick={() => setError({ ...error, attachment: false })}
            >
              Close
            </div>
          </div>
        </Dialog>
      )}
      {showSuccess && (
        <Dialog>
          <div
            className="ContactUs_popup_cross_button"
            onClick={() => setShowSuccess(false)}
          >
            X
          </div>
          <div className="ContactUs_popup_parent_box">
            <div className="ContactUs_popup_title">Success!</div>
            <div className="ContactUs_popup_detail">
              Your feedback has been submitted.
            </div>
            <div
              className="ContactUs_popup_close_button"
              onClick={() => setShowSuccess(false)}
            >
              Close
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};
