import { useState, useEffect } from "react";
import CognitoContext from "./cognitoContext";
import { Loader } from "@progress/kendo-react-indicators";
import api from "./config/api";
import config from "./config/config";
import { deleteFirebaseToken } from "./firebase";

import {
  REGISTER_ROUTE,
  VERIFY_OTP_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE,
} from "./routes";

const {
  getCurrentLoggedInSessionTokenWithPromise,
  getCurrentLoggedInUserWithPromise,
  requrieRefresh,
  signout,
} = require("./config/userPool");

const CognitoProvider = ({ children }) => {
  const [cognitoToken, setCognitoToken] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const { GET_USER_ALT_ENDPOINT, USER_SESSION_ENDPOINT } = config.api_endpoint;

  useEffect(() => {
    // Code to get and refresh the Cognito token goes here
    console.log("CognitoProvider UseEffect");

    const getIdToken = () => {
      const prom = getCurrentLoggedInSessionTokenWithPromise();
      prom.then(
        (result) => {
          console.log(
            "getIdToken - getCurrentLoggedInSessionTokenWithPromise Success:",
            result
          );
          setCognitoToken(result);
          setIsLoading(false);
          localStorage.setItem("shouldLogout", "false");
        },
        (error) => {
          console.log(
            "getIdToken - getCurrentLoggedInSessionTokenWithPromise Error:",
            error
          );
          setCognitoToken(null);
          setIsLoading(false);

          const ROUTES_CAN_ACCESS_WITHOUT_LOGIN = [
            REGISTER_ROUTE,
            VERIFY_OTP_ROUTE,
            FORGOT_PASSWORD_ROUTE,
            RESET_PASSWORD_ROUTE,
          ];

          if (
            window.location.pathname !== "/" &&
            ROUTES_CAN_ACCESS_WITHOUT_LOGIN.indexOf(
              window.location.pathname
            ) === -1
          ) {
            // alert("Your login session has expired. Please login again.");
            localStorage.removeItem("login");
            localStorage.removeItem("username");
            localStorage.removeItem("shouldLogout");
            localStorage.removeItem("session_id");
            window.location.href = "/";
            localStorage.setItem("redirect_to", window.location.href);
          }
        }
      );
    };

    const getUserPayload = () => {
      const prom = getCurrentLoggedInUserWithPromise();
      prom.then(
        (result) => {
          console.log(
            "getUserPayload - getCurrentLoggedInUserWithPromise Success:",
            result
          );
          setCognitoUser(result);
          setIsUserLoading(false);
        },
        (error) => {
          console.log(
            "getUserPayload - getCurrentLoggedInUserWithPromise Error:",
            error
          );
          setCognitoUser(null);
          setIsUserLoading(false);
        }
      );
    };

    const checkPasswordExpiry = async () => {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const user = await getCurrentLoggedInUserWithPromise();
      console.log("checkPasswordExpiry - token:", token, "user:", user);
      api
        .get(GET_USER_ALT_ENDPOINT, { uid: user["sub"] }, token)
        .then((res) => {
          console.log("checkPasswordExpiry - get user alt res", res);
          let now = new Date();
          let expired_at = new Date(res.data.password_expired_at);
          console.log(
            "checkPasswordExpiry - Has password expired:",
            expired_at,
            now,
            expired_at <= now
          );
          if (expired_at <= now) {
            if (window.location.href.indexOf("change_password") < 0) {
              alert("Password has expired. Please change your password.");
              window.location.href = "/change_password";
            }
          }
        })
        .catch((err) => {
          console.log("checkPasswordExpiry - get user alt err", err);
        });
    };

    const checkSessionID = async () => {
      const sessionId = localStorage.getItem("session_id");
      const ROUTES_CAN_ACCESS_WITHOUT_LOGIN = [
        REGISTER_ROUTE,
        VERIFY_OTP_ROUTE,
        FORGOT_PASSWORD_ROUTE,
        RESET_PASSWORD_ROUTE,
      ];

      if (!sessionId || sessionId === undefined || sessionId === null) {
        if (
          window.location.pathname !== "/" &&
          ROUTES_CAN_ACCESS_WITHOUT_LOGIN.indexOf(window.location.pathname) ===
            -1
        ) {
          console.log(
            "checkSessionID - session_id not available. redirecting to login."
          );
          signout();
          localStorage.removeItem("login");
          localStorage.removeItem("username");
          localStorage.removeItem("shouldLogout");
          localStorage.removeItem("session_id");
          window.location.href = "/";
          localStorage.setItem("redirect_to", window.location.href);
        }
      } else {
        const token = await getCurrentLoggedInSessionTokenWithPromise();
        console.log("checkSessionID - token:", token);
        api
          .get(
            USER_SESSION_ENDPOINT,
            { session_id: localStorage.getItem("session_id") },
            token
          )
          .then((res) => {
            console.log("checkSessionID - get user session res", res);
            if (!res.data.id) {
              console.log(
                "checkSessionID - session_id does not exist. redirecting to login."
              );
              signout();
              localStorage.removeItem("login");
              localStorage.removeItem("username");
              localStorage.removeItem("shouldLogout");
              localStorage.removeItem("session_id");
              window.location.href = "/";
              localStorage.setItem("redirect_to", window.location.href);
            }
          })
          .catch((err) => {
            console.log("checkSessionID - get user session err", err);
          });
      }
    };

    getIdToken();
    getUserPayload();
    checkPasswordExpiry();
    checkSessionID();

    const intervalId = setInterval(() => {
      console.log("CONTEXT INTERVAL IS RUNNING.");
      const doRequire = requrieRefresh();
      console.log("Required to refresh token: ", doRequire);
      if (doRequire) {
        getIdToken();
        getUserPayload();
      }
      const ROUTES_CAN_ACCESS_WITHOUT_LOGIN = [
        REGISTER_ROUTE,
        VERIFY_OTP_ROUTE,
        FORGOT_PASSWORD_ROUTE,
        RESET_PASSWORD_ROUTE,
      ];

      if (
        window.location.pathname !== "/" &&
        ROUTES_CAN_ACCESS_WITHOUT_LOGIN.indexOf(window.location.pathname) === -1
      ) {
        checkPasswordExpiry();
        checkSessionID();
      }
    }, 1000 * 10); // Check every 10 seconds

    return () => clearInterval(intervalId);
  }, []);

  const contextValue = { cognitoToken, cognitoUser };

  return (
    <CognitoContext.Provider value={contextValue}>
      {isLoading || isUserLoading ? (
        <div style={{ width: "100vw", height: "100vh" }}>
          <Loader
            type="infinite-spinner"
            style={{
              // height: "89vh",
              display: "flex",
              alignItems: "center",
              width: "40px",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              minHeight: "100%",
            }}
          />
        </div>
      ) : (
        children
      )}
    </CognitoContext.Provider>
  );
};

export default CognitoProvider;
