// redux
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";

const {
  getCurrentLoggedInSessionTokenWithPromise,
} = require("../config/userPool");

const { INCIDENT_LIST_ENDPOINT } = config.api_endpoint;
const { VIEW_INCIDENT, VIEW_INCIDENT_LIMITED, VIEW_ECM_INCIDENT, VIEW_ECM_INCIDENT_LIMITED } = config.permissions;
const initialState = {
  loading: true,
  incidents: [],
  error: null,
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("current_user"));
};

const shouldAccessAll = (user) => {
  if (user.permissions.indexOf(VIEW_INCIDENT) > -1) {
    return true;
  } else if (user.permissions.indexOf(VIEW_INCIDENT_LIMITED) > -1) {
    return false;
  }
  return false;
};

const shouldECMAccessAll = (user) => {
  if (user.permissions.indexOf(VIEW_ECM_INCIDENT) > -1) {
    return true;
  } else if (user.permissions.indexOf(VIEW_ECM_INCIDENT_LIMITED) > -1) {
    return false;
  }
  return false;
};

const hasECMViewAccess = (user) => {
  if (user.permissions.indexOf(VIEW_ECM_INCIDENT) > -1 || user.permissions.indexOf(VIEW_ECM_INCIDENT_LIMITED)) {
    return true;
  }
  return false;
}

const hasViewAccess = (user) => {
  if (user.permissions.indexOf(VIEW_INCIDENT) > -1 || user.permissions.indexOf(VIEW_INCIDENT_LIMITED)) {
    return true;
  }
  return false;
}

export const fetchAllIncidents = createAsyncThunk(
  "incident/fetchAllIncidents",
  async (UID, { rejectWithValue }) => {
    try {
      const currentUser = getCurrentUser();
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const { data: results } = await api.get(
        INCIDENT_LIST_ENDPOINT,
        {
          filter_package: !shouldAccessAll(currentUser)
            ? currentUser["package"].join(",")
            : "",
          filter_ecm_package: !shouldECMAccessAll(currentUser)
            ? currentUser["package"].join(",")
            : "",
          access_ecm: currentUser["permissions"].indexOf(VIEW_ECM_INCIDENT_LIMITED) > -1 || currentUser.permissions.indexOf(VIEW_ECM_INCIDENT) > -1,
          access_case: currentUser["permissions"].indexOf(VIEW_INCIDENT_LIMITED) > -1 || currentUser.permissions.indexOf(VIEW_INCIDENT) > -1,
          exclude_uid: UID && true,
        },
        token
      );

      const copiedResults = results.filter(
        (x) =>
          !(
            x.incident_status === "draft" &&
            x.created_uid !== currentUser["cognito:username"]
          )
      );
      return { incidents: copiedResults };
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

const incidentSlice = createSlice({
  name: "incident",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllIncidents.pending, (state) => {
        console.log("fetch all incidents pending: ", state);
        state.loading = true;
      })
      .addCase(fetchAllIncidents.fulfilled, (state, action) => {
        console.log("fetch all incidents fulfilled: ", state, action);
        state.loading = false;
        state.incidents = action.payload.incidents;
      })
      .addCase(fetchAllIncidents.rejected, (state, action) => {
        console.log("fetch all incidents rejected: ", state, action);
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const incidentReducer = incidentSlice.reducer;
export default incidentReducer;
