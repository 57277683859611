import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { LIST_INBOX_ENDPOINT } = config.api_endpoint;

const initialState = {
  loading: true,
  inbox: [],
  error: null,
};

export const filterINBOX = createAsyncThunk(
  "inbox/fetchALLINBOX",
  async (type, { rejectWithValue }) => {
    console.log(type);
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const result = await api.get(
        LIST_INBOX_ENDPOINT,
        {
          filter_type: type.filter_type,
          page: 1,
          page_size: type.page_size,
          user_id: type.user_id,
          notification_type: type.notification_type,
          is_fav: type.is_fav,
          start_date: type.start_date,
          end_date: type.end_date,
        },
        token
      );
      console.log(result);
      return {
        inbox: result,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchALLINBOX = createAsyncThunk(
  "inbox/fetchALLINBOX",
  async (type, { rejectWithValue }) => {
    console.log(type);
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const result = await api.get(
        LIST_INBOX_ENDPOINT,
        {
          filter_type: type.filter_type,
          page: 1,
          page_size: type.page_size,
          user_id: type.user_id,
          notification_type: type.notification_type,
        },
        token
      );
      console.log(result);
      return {
        inbox: result,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.details);
    }
  }
);

const inboxSlice = createSlice({
  name: "inbox",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchALLINBOX.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchALLINBOX.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.inbox = action.payload.inbox.data;
      })
      .addCase(fetchALLINBOX.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const inboxReducer = inboxSlice.reducer;
export default inboxReducer;
