// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../config/api";
import config from "../config/config";

const { GET_CONFIGURATION_ENDPOINT } = config.api_endpoint;
const initialState = {
  cag_rw_loading: true,
  cag_rw_data: [],
  cag_rw_error: null,
  height_template_loading: true,
  height_template_data: [],
  height_template_error: null,
  road_name_loading: true,
  road_name_data: [],
  road_name_error: null,
  site_util_loading: true,
  site_util_data: [],
  site_util_error: null,
  lamp_data_loading: true,
  lamp_data: [],
  lamp_data_error: null,
  lamp_package_loading: true,
  lamp_package_data: [],
  lamp_package_error: null,
  general_config_loading: true,
  general_config_data: {},
  general_config_error: null,
};

export const fetchGeneralConfig = createAsyncThunk(
  "config/fetchGeneralConfig",
  async (_, { rejectWithValue }) => {
    try {
      const results = await api.get(
        GET_CONFIGURATION_ENDPOINT,
        {}
      );
      console.log('General Config Json:', results);
      return {
        general_config_data: results.data,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchCAGRWData = createAsyncThunk(
  "config/fetchCAGRWData",
  async (_, { rejectWithValue }) => {
      try {
      let url = config.static_url + "/staticfiles/json/RW_CAG_CN_NAV_PMG_A19_SP-1001AQ_WS SVY21 [binded Mar23]_SVY21_exported.json";
      let resp = await fetch(url);
      let res = await resp.json();
      console.log('CAG RW Json:', res);
      return res;
    }
    catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchHeightTemplateData = createAsyncThunk(
  "config/fetchHeightTemplateData",
  async (_, { rejectWithValue }) => {
      try {
      let url = config.static_url + "/staticfiles/json/Height Template.json";
      let resp = await fetch(url);
      let res = await resp.json();
      console.log('Height Template Json:', res);
      return res;
    }
    catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchRoadNameData = createAsyncThunk(
  "config/fetchRoadNameData",
  async (_, { rejectWithValue }) => {
      try {
      let url = config.static_url + "/staticfiles/json/Road_Names_line (1).json";
      let resp = await fetch(url);
      let res = await resp.json();
      console.log('Road Name Json:', res);
      return res;
    }
    catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchSiteUtilData = createAsyncThunk(
  "config/fetchSiteUtilData",
  async (_, { rejectWithValue }) => {
      try {
      let url = config.static_url + "/staticfiles/json/Site utilisation Jun 2021 (1).json";
      let resp = await fetch(url);
      let res = await resp.json();
      console.log('Site Util Json:', res);
      return res;
    }
    catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchLampData = createAsyncThunk(
  "config/fetchLampData",
  async (_, { rejectWithValue }) => {
      try {
      let url = config.static_url + "/staticfiles/json/lamp_data.json";
      let resp = await fetch(url);
      let res = await resp.json();
      console.log('Lamp Data Json:', res);
      return res;
    }
    catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

export const fetchLampPkgData = createAsyncThunk(
  "config/fetchLampPkgData",
  async (_, { rejectWithValue }) => {
      try {
      let url = config.static_url + "/staticfiles/json/lamp_package.json";
      let resp = await fetch(url);
      let res = await resp.json();
      console.log('Lamp Pkg Json:', res);
      return res;
    }
    catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const generalConfigSlice = createSlice({
  name: "config",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCAGRWData.pending, (state) => {
        state.cag_rw_loading = true;
      })
      .addCase(fetchCAGRWData.fulfilled, (state, action) => {
        console.log(action);
        state.cag_rw_loading = false;
        state.cag_rw_data = action.payload;
      })
      .addCase(fetchCAGRWData.rejected, (state, action) => {
        state.cag_rw_loading = false;
        state.cag_rw_error = action.error.message;
      })
      .addCase(fetchHeightTemplateData.pending, (state) => {
        state.height_template_loading = true;
      })
      .addCase(fetchHeightTemplateData.fulfilled, (state, action) => {
        console.log(action);
        state.height_template_loading = false;
        state.height_template_data = action.payload;
      })
      .addCase(fetchHeightTemplateData.rejected, (state, action) => {
        state.height_template_loading = false;
        state.height_template_error = action.error.message;
      })
      .addCase(fetchRoadNameData.pending, (state) => {
        state.road_name_loading = true;
      })
      .addCase(fetchRoadNameData.fulfilled, (state, action) => {
        console.log(action);
        state.road_name_loading = false;
        state.road_name_data = action.payload;
      })
      .addCase(fetchRoadNameData.rejected, (state, action) => {
        state.road_name_loading = false;
        state.road_name_error = action.error.message;
      })
      .addCase(fetchSiteUtilData.pending, (state) => {
        state.site_util_loading = true;
      })
      .addCase(fetchSiteUtilData.fulfilled, (state, action) => {
        console.log(action);
        state.site_util_loading = false;
        state.site_util_data = action.payload;
      })
      .addCase(fetchSiteUtilData.rejected, (state, action) => {
        state.site_util_loading = false;
        state.site_util_error = action.error.message;
      })
      .addCase(fetchLampData.pending, (state) => {
        state.lamp_data_loading = true;
      })
      .addCase(fetchLampData.fulfilled, (state, action) => {
        console.log(action);
        state.lamp_data_loading = false;
        state.lamp_data = action.payload;
      })
      .addCase(fetchLampData.rejected, (state, action) => {
        state.lamp_data_loading = false;
        state.lamp_data_error = action.error.message;
      })
      .addCase(fetchLampPkgData.pending, (state) => {
        state.lamp_package_loading = true;
      })
      .addCase(fetchLampPkgData.fulfilled, (state, action) => {
        console.log(action);
        state.lamp_package_loading = false;
        state.lamp_package_data = action.payload;
      })
      .addCase(fetchLampPkgData.rejected, (state, action) => {
        state.lamp_package_loading = false;
        state.lamp_package_error = action.error.message;
      })
      .addCase(fetchGeneralConfig.pending, (state) => {
        state.general_config_loading = true;
      })
      .addCase(fetchGeneralConfig.fulfilled, (state, action) => {
        console.log(action);
        state.general_config_loading = false;
        state.general_config_data = action.payload;
      })
      .addCase(fetchGeneralConfig.rejected, (state, action) => {
        state.general_config_loading = false;
        state.general_config_error = action.error.message;
      });
  },
});

const generalConfigReducer = generalConfigSlice.reducer;
export default generalConfigReducer;
