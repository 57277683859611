import { React, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextBox, InputSuffix } from "@progress/kendo-react-inputs";
import CalendarIcon from "../../assets/images/Work Declaration/CalendarWhite.svg";
import moment from "moment/moment";
import "./timepicker.css";

export const CustomDatePicker = (props) => {
  console.log(props);
  const [showPicker, setShowPicker] = useState(false);

  const onChangeValue = (e) => {
    props.onChange(e);
    setShowPicker(!showPicker);
  };
  return (
    <>
      <TextBox
        className="sitrepDatePicker"
        value={moment(props.value).format(props.format)}
        disabled={props.disabled && true}
        suffix={() => (
          <>
            <InputSuffix
              onClick={() => !props.disabled && setShowPicker(!showPicker)}
              style={{ cursor: props.disabled ? "" : "pointer" }}
            >
              <img src={CalendarIcon} />
            </InputSuffix>
          </>
        )}
      />
      {showPicker && (
        <div className="float-datePicker">
          <DatePicker
            calendarClassName="resizing"
            showYearDropdown
            dateFormatCalendar="MMMM"
            yearDropdownItemNumber={15}
            scrollableYearDropdown
            selected={props.value}
            minDate={props.MinDate && props.MinDate}
            maxDate={props.MaxDate && props.MaxDate}
            onChange={(e) => onChangeValue(e)}
            onClickOutside={() => setShowPicker(false)}
            inline
          />
        </div>
      )}
    </>
  );
};
