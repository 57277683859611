import React from "react";
import "./term_of_use.css";

export const TermOfUse = () => {
  return (
    <div className="Term_main_box">
      <div className="Term_title">TERMS OF USE</div>
      <div className="Term_des">
        Please read this carefully before installing the{" "}
        <span style={{ fontWeight: "700" }}>CEreal</span> application
        (collectively the "Application") provided by Changi Airport Group
        (Singapore) Pte. Ltd. ("CAG").
      </div>
      <div className="Term_des">
        By installing or using the Application, you indicate acceptance of these
        terms set out herein. If you do not accept these terms, you should not
        install or use the Software.
      </div>
      {/* <div className="Term_des">
        Please note that if you log into your Changi Account, the{" "}
        <span className="Term_des_text_decoration">
          <a
            href="https://www.changiairport.com/en/terms-and-conditions.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Changi Account Terms and Conditions
          </a>
        </span>{" "}
        will apply.
      </div> */}
      <div className="Term_des_title_box">
        <div>1.</div>
        <div>Ownershop of materials</div>
      </div>
      <div className="Term_des_sec_child_box">
        <div className="Term_des_sec_child_box_first_child">
          <div>1.1</div>
          <div>
            This Application is owned and operated by CAG. Access to—and use
            of—this Application, and the information and services available are
            subject to the following conditions.
          </div>
        </div>
        <div className="Term_des_sec_child_box_last_child">
          <div>1.2</div>
          <div>
            This Application may contain links to websites also operated by CAG.
            Your use of each CAG Website is also subject to its terms of use, if
            any, contained therein. If any of the conditions here contradict
            with the conditions contained in any of these other websites
            operated by CAG ("CAG Websites"), then the conditions in these CAG
            Websites shall prevail when you access/use them.
          </div>
        </div>
      </div>
      {/* 2 */}
      <div className="Term_des_title_box">
        <div>2.</div>
        <div>Restrictions on access and use</div>
      </div>
      <div className="Term_des_sec_child_box">
        <div className="Term_des_sec_child_box_first_child">
          <div>2.1</div>
          <div>
            This Application and all copyrights, trademarks, patents and other
            intellectual property rights are owned or controlled by or licensed
            to CAG and are protected by the relevant intellectual property laws.
          </div>
        </div>
        <div className="Term_des_sec_child_box_first_child">
          <div>2.2</div>
          <div>
            <div style={{ marginBottom: "20px" }}>
              Unless otherwise agreed by CAG, you may download one copy of the
              material on this Application, but only on a single computer and
              only for your own personal and non-commercial use, provided that:
            </div>
            <div className="Term_des_sec_child_box_first_child">
              <div>(a)</div>
              <div>
                All copies must bear the relevant copyright, trademark and/or
                other proprietary notice located on this Application; and
              </div>
            </div>
            <div className="Term_des_sec_child_box_last_child">
              <div>(b)</div>
              <div>
                You shall not modify, reformat, copy, disclose, reproduce,
                display, distribute, publish, transmit, decompile, disassemble,
                reverse engineer, modify, post, upload, licence, create
                derivative works from, hyperlink, store in any information
                retrieval system, transfer in any manner or sell any of the
                material in the Application or this Application (or any part
                thereof), without written permission from CAG. This means, among
                other things, that you must not insert a hyperlink to this
                Application on any other website or 'mirror' on your own
                website, the home page or other pages of—or materials in—this
                Application or any CAG Websites or their respective links.
              </div>
            </div>
          </div>
        </div>
        <div className="Term_des_sec_child_box_first_child">
          <div>2.3</div>
          <div>
            You shall not use this Application, or its links for any purpose
            that is illegal, unlawful or prohibited by these Terms of Use. You
            may only use this Application and the information contained therein
            for the purposes intended and contemplated under these Terms of Use.
          </div>
        </div>
        <div className="Term_des_sec_child_box_last_child">
          <div>2.4</div>
          <div>
            CAG reserves the right to deny anyone at anytime access to this
            Application—or any other—CAG Websites without notice or need to
            assign any reason whatsoever.
          </div>
        </div>
      </div>
      {/* 3 */}
      <div className="Term_des_title_box">
        <div>3.</div>
        <div>Use of data</div>
      </div>
      <div className="Term_des_sec_child_box">
        <div className="Term_des_sec_child_box_last_child">
          <div>3.1</div>
          <div>
            You shall permit CAG to collect and use data and information
            originated by you and made accessible to CAG in the course of or as
            a result of your use of the Application, for the purpose of
            facilitating CAG’s operational and safety needs in relation to the
            Application. CAG may use such data and information to improve its
            operations.
          </div>
        </div>
      </div>
      {/* 4 */}
      <div className="Term_des_title_box">
        <div>4.</div>
        <div>Disclaimer of Representation and Warranties</div>
      </div>
      <div className="Term_des_sec_child_box">
        <div className="Term_des_sec_child_box_first_child">
          <div>4.1</div>
          <div>
            Without prejudice to clause 4.2, while every effort will be made by
            CAG to ensure that information provided through the Application is
            correct, the details provided may, from time to time, be changed
            without notification. CAG is not responsible for any incorrect
            information and is therefore not liable in any way for inaccuracies
            or errors in the same.
          </div>
        </div>
        <div className="Term_des_sec_child_box_first_child">
          <div>4.2</div>
          <div>
            <div style={{ marginBottom: "20px" }}>
              You acknowledge and agree that the content (including third party
              information, products and/or services of – or accessible through –
              this application, and all other CAG websites are provided:
            </div>
            <div className="Term_des_sec_child_box_first_child">
              <div>(a)</div>
              <div>
                "as is", "as available" and are subject to change at any time
                without notice to you; and
              </div>
            </div>
            <div className="Term_des_sec_child_box_last_child">
              <div>(b)</div>
              <div>
                without representations or warranties of any kind whether
                express or implied.
              </div>
            </div>
          </div>
        </div>
        <div className="Term_des_sec_child_box_last_child">
          <div>4.3</div>
          <div>
            Your access, use, reliance or utilisation of this application (or
            any part thereof) shall be done at your own risk.
          </div>
        </div>
      </div>
      {/* 5 */}
      <div className="Term_des_title_box">
        <div>5.</div>
        <div>Exclusion of Liability</div>
      </div>
      <div className="Term_des_sec_child_box">
        <div className="Term_des_sec_child_box_last_child">
          <div>5.1</div>
          <div>
            IN NO EVENT SHALL CAG OR ANY OF ITS AFFILIATES OR SUBSIDIARIES BE
            LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING—BUT NOT LIMITED TO—ANY
            DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, PUNITIVE OR INCIDENTAL
            DAMAGES, OR DAMAGES FOR LOSS OF USE, PROFITS, DATA OR OTHER
            INTANGIBLES, OR THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR
            SERVICES ARISING OUT OF—OR RELATED TO— ANY BREACH OF SECURITY,
            DELAY, CORRUPTION OR DESTRUCTION OF DATA OR SYSTEMS (INCLUDING BUT
            NOT LIMITED TO THOSE CAUSED BY ANY VIRUS OR OTHER MALICIOUS CODE),
            TRANSMISSION ERROR, INACCESSIBILITY OF THIS APPLICATION OR ANY PART
            THEREOF (WHETHER IN CONNECTION WITH UPGRADING OR MODIFICATION OF
            THIS APPLICATION, FAILURE OR INSUFFICIENCY OF THE TECHNICAL
            FACILITIES OR OTHERWISE – OR THE USE, INABILITY TO USE, UNAUTHORISED
            USE, PERFORMANCE OR NON- PERFORMANCE OF—OR RELIANCE UPON—THIS
            APPLICATION OR ANY OTHER CAG WEBSITES EVEN IF CAG HAS BEEN ADVISED
            PREVIOUSLY OF THE POSSIBILITY OF SUCH DAMAGES AND WHETHER OR NOT
            SUCH DAMAGES ARISE IN CONTRACT, TORT, UNDER STATUTE, IN EQUITY OR AT
            LAW.
          </div>
        </div>
      </div>
      {/* 6 */}
      <div className="Term_des_title_box">
        <div>6.</div>
        <div>Links to third-party websites</div>
      </div>
      <div className="Term_des_sec_child_box">
        <div className="Term_des_sec_child_box_first_child">
          <div>6.1</div>
          <div>
            The websites to which this Application or any other CAG Websites is
            linked may be developed and/or operated by parties other than CAG.
          </div>
        </div>
        <div className="Term_des_sec_child_box_first_child">
          <div>6.2</div>
          <div>
            CAG does not control such websites and is not responsible for their
            contents. CAG’s inclusion of links to these websites does not in
            itself constitute or imply any endorsement, sponsorship or
            recommendation of the material on such websites or any association
            with their owner and/or operators (unless expressly stated
            otherwise).
          </div>
        </div>
        <div className="Term_des_sec_child_box_last_child">
          <div>6.3</div>
          <div>
            Any third party who wishes to establish links to this Application,
            or any other CAG Websites should notify CAG of its intention in
            writing prior to doing so. CAG reserves the right to deny permission
            for any such link without need to assign any reason. If permission
            is given, CAG is not under any obligation to establish reciprocal
            links.
          </div>
        </div>
      </div>
      {/* 7 */}
      <div className="Term_des_title_box">
        <div>7.</div>
        <div>Feedback</div>
      </div>
      <div className="Term_des_sec_child_box">
        <div className="Term_des_sec_child_box_last_child">
          <div>7.1</div>
          <div>
            All feedback given to CAG, including questions, comments,
            suggestions or the like regarding—or in response to—the content of
            this Application, or any other CAG Websites, shall be deemed to be
            non-confidential. CAG shall be free to use, for any purpose, ideas,
            concepts, techniques or know-how contained in such feedback. CAG
            shall not be subject to any obligation with respect to such feedback
            and shall be free to reproduce, use, disclose and distribute to
            others without limitation.
          </div>
        </div>
      </div>
      {/* 8 */}
      <div className="Term_des_title_box">
        <div>8.</div>
        <div>Personal Data</div>
      </div>
      <div className="Term_des_sec_child_box">
        <div className="Term_des_sec_child_box_last_child">
          <div>8.1</div>
          <div>
            You agree that CAG may collect, use and disclose your Personal Data
            (as defined under the Personal Data Protection Act 2012 (“PDPA”)),
            and other relevant information as provided in this Software, for the
            purpose of verifying your identity for security purposes and
            operational management of Changi East (CE) worksite. Your Personal
            Data may be disclosed to third parties where necessary for
            operational and security matters and/or arrangements, facilitating
            compliance with any laws and regulations which may be applicable, to
            facilitate investigations, and to manage the administrative and
            business operations, which will be in accordance with the PDPA and{" "}
            <a
              href="https://www.changiairport.com/en/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              CAG's Privacy Policy{" "}
            </a>
            .
          </div>
        </div>
      </div>
      {/* 9 */}
      <div className="Term_des_title_box">
        <div>9.</div>
        <div>No Licence granted</div>
      </div>
      <div className="Term_des_sec_child_box">
        <div className="Term_des_sec_child_box_last_child">
          <div>9.1</div>
          <div>
            Nothing on—or your use of—this Application, or any other CAG
            Websites shall be construed as conferring any licence or other
            rights under the intellectual property or proprietary rights of CAG,
            its affiliates or subsidiaries, whether by estoppel, implication or
            otherwise.
          </div>
        </div>
      </div>
      {/* 10 */}
      <div className="Term_des_title_box">
        <div>10.</div>
        <div>General</div>
      </div>
      <div className="Term_des_sec_child_box">
        <div className="Term_des_sec_child_box_first_child">
          <div>10.1</div>
          <div>
            These Terms of Use may be amended at any time by CAG without notice
            to you. Any use by you of the Application subsequent to the any
            amendment made by CAG to these Terms of Use, shall be deemed to be
            an acceptance by you of such amendment.
          </div>
        </div>
        <div className="Term_des_sec_child_box_first_child">
          <div>10.2</div>
          <div>
            No delay, neglect or forebearance by CAG in enforcing against you
            any term or condition of these Terms of Use shall be deemed to be a
            waiver or in any way prejudice any right of CAG. No right, power or
            remedy in these Terms of Use conferred upon or reserved for either
            party is exclusive of any other right, power or remedy available to
            CAG.
          </div>
        </div>
        <div className="Term_des_sec_child_box_first_child">
          <div>10.3</div>
          <div>
            These Terms of Use constitute the entire agreement between you and
            CAG and you agree that any and all prior and contemporaneous
            agreement relating to your access and use of this Application, or
            any other CAG Websites are superseded.
          </div>
        </div>
        <div className="Term_des_sec_child_box_first_child">
          <div>10.4</div>
          <div>
            If any provision of these Terms of Use is determined to be invalid
            or unenforceable, then such invalidity or unenforceability shall not
            have any effect on any other provision of these Terms of Use, all of
            which shall remain valid and enforceable.
          </div>
        </div>
        <div className="Term_des_sec_child_box_first_child">
          <div>10.5</div>
          <div>
            These Terms of Use and all matters arising from it and any disputes
            in respect thereof, shall be governed by and construed in accordance
            with the laws of the Republic of Singapore, including—but not
            limited to—the Evidence Act (Cap. 97) and the Electronic
            Transactions Act (No. 25 of 1998), regardless of any other choice of
            law provisions. You hereby irrevocably submit to the non-exclusive
            jurisdiction of the Singapore courts.
          </div>
        </div>
        <div className="Term_des_sec_child_box_first_child">
          <div>10.6</div>
          <div>
            At CAG’s option, any dispute or difference arising out of—or in
            connection with—these Terms of Use, this Application, or any other
            website operated by CAG, shall be referred to—and resolved
            by—arbitration in Singapore, in the English language, and in
            accordance with the Arbitration Rules of the Singapore International
            Arbitration Centre, for the time being in force which rules are
            deemed to be incorporated by reference to this clause.
          </div>
        </div>
        <div className="Term_des_sec_child_box_last_child">
          <div>10.7</div>
          <div>
            In the event that the laws of the jurisdiction you are in do not
            permit or impose restrictions on the access to the contents of this
            Application or any other CAG Websites, you shall immediately
            discontinue access or comply with such restrictions (as the case may
            be).
          </div>
        </div>
      </div>
    </div>
  );
};
