// redux
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";

const {
  getCurrentLoggedInSessionTokenWithPromise,
} = require("../config/userPool");

const { GET_USERS_ENDPOINT } = config.api_endpoint;
const initialState = {
  loading: true,
  users: [],
  error: null,
};

export const fetchAllUsers = createAsyncThunk(
  "user/fetchAllUsers",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const { data: results } = await api.get(GET_USERS_ENDPOINT, {}, token);
      return { users: results };
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

export const fetchAllUsersGroupByDesignation = createAsyncThunk(
  "user/fetchAllUsersGroupByDesignation",
  async (_, { rejectWithValue }) => {
    try {
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const { data: results } = await api.get(
        GET_USERS_ENDPOINT,
        {
          group_by: "designation",
        },
        token
      );
      return { users: results };
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error.details);
    }
  }
);

const userSlice = createSlice({
  name: "incident",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsersGroupByDesignation.pending, (state) => {
        console.log("fetch all users pending: ", state);
        state.loading = true;
      })
      .addCase(fetchAllUsersGroupByDesignation.fulfilled, (state, action) => {
        console.log("fetch all users fulfilled: ", state, action);
        state.loading = false;
        state.users = action.payload.users;
      })
      .addCase(fetchAllUsersGroupByDesignation.rejected, (state, action) => {
        console.log("fetch all users rejected: ", state, action);
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchAllUsers.pending, (state) => {
        console.log("fetch all users pending: ", state);
        state.loading = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        console.log("fetch all users fulfilled: ", state, action);
        state.loading = false;
        state.users = action.payload.users;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        console.log("fetch all users rejected: ", state, action);
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const userReducer = userSlice.reducer;
export default userReducer;
