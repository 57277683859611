// redux

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../config/api";
import config from "../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../config/userPool";

const { GET_WORKDECLARATION_LIST } = config.api_endpoint;
const { VIEW_WORK_DECLARATION, VIEW_WORK_DECLARATION_LIMITED } =
  config.permissions;

const initialState = {
  loading: true,
  workList: [],
  error: null,
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("current_user"));
};

const shouldAccessAll = (user) => {
  if (user.permissions.indexOf(VIEW_WORK_DECLARATION) > -1) {
    return true;
  } else if (user.permissions.indexOf(VIEW_WORK_DECLARATION_LIMITED) > -1) {
    return false;
  }
  return false;
};

export const fetchALLWork = createAsyncThunk(
  "workList/fetchALLWork",
  async (_, { rejectWithValue }) => {
    try {
      const currentUser = getCurrentUser();
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const results = await api.get(
        GET_WORKDECLARATION_LIST,
        {
          package: !shouldAccessAll(currentUser)
            ? currentUser["package"].join(",")
            : "",
          page: 1,
          page_size: 10,
        },
        token
      );
      console.log(results);
      return {
        workList: results.data,
      };
    } catch (error) {
      return rejectWithValue(error.details);
    }
  }
);

const WorkSlice = createSlice({
  name: "workList",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchALLWork.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchALLWork.fulfilled, (state, action) => {
        console.log(action);
        state.loading = false;
        state.workList = action.payload;
      })
      .addCase(fetchALLWork.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

const WorkReducer = WorkSlice.reducer;
export default WorkReducer;
